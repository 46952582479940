import request from '@/utils/request'
// 城市列表
export function cityListTree() {
  return request({
    url: '/sys/area/tree',
    method: 'get'
  })
}
// 获取特定等级城市列表
export function getCityList(data) {
  return request({
    url: '/sys/area/list',
    method: 'get',
    params: { ...data}
  })
}

// 物流运费模板列表 2022-03-29
export function shippingTemplatesList(data) {
  return request({
    url: 'zb/shipping-templates/page',
    method: 'get',
    params: { ...data }
  })
}

// 物流运费模板详情
export function templateDetailApi(data) {
  return request({
    url: 'zb/shipping-templates/get',
    method: 'get',
    params: { ...data }
  })
}

// 物流运费模板新增 2022-03-29
export function shippingSave(data) {
  return request({
    url: 'zb/shipping-templates/create',
    method: 'post',
    data: data
  })
}

// 物流运费模板更新
export function shippingUpdate(data) {
  return request({
    url: 'zb/shipping-templates/update',
    method: 'post',
    data: data
  })
}

// 物流运费模板删除
export function shippingDetete(data) {
  return request({
    url: 'zb/shipping-templates/delete',
    method: 'delete',
    params: data
  })
}
// // 城市详情
// export function cityInfo(data) {
//   return request({
//     url: '/admin/system/city/info',
//     method: 'get',
//     params: { ...data }
//   })
// }
// 城市列表
// export function cityList(data) {
//   return request({
//     url: '/admin/system/city/list',
//     method: 'get',
//     params: { ...data }
//   })
// }
// 城市修改状态
// export function updateStatus(data) {
//   return request({
//     url: '/admin/system/city/update/status',
//     method: 'post',
//     params: { ...data }
//   })
// }

// 城市修改
// export function cityUpdate(data) {
//   return request({
//     url: '/admin/system/city/update',
//     method: 'post',
//     params: { ...data }
//   })
// }



// 物流公司列表
// export function expressList(data) {
//   return request({
//     url: '/admin/express/list',
//     method: 'get',
//     params: { ...data }
//   })
// }

// // 同步物流公司
// export function expressSyncApi() {
//   return request({
//     url: '/admin/express/sync/express',
//     method: 'post'
//   })
// }

// // 物流公司修改状态
// export function expressUpdateShow(data) {
//   return request({
//     url: '/admin/express/update/show',
//     method: 'post',
//     data
//   })
// }

// 物流公司 编辑
// export function expressUpdate(data) {
//   return request({
//     url: '/admin/express/update',
//     method: 'post',
//     data
//   })
// }

// // 新增物流公司
// export function expressSave(data) {
//   return request({
//     url: '/admin/express/save',
//     method: 'post',
//     params: { ...data }
//   })
// }

// // 删除物流公司
// export function expressDelete(data) {
//   return request({
//     url: '/admin/express/delete',
//     method: 'GET',
//     params: { ...data }
//   })
// }

