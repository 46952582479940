import request from '@/utils/request'

const userApi = {
  Login: '/login',
  Logout: '/logout',
  Register: '/register',
  // get my info
  UserInfo: '/get-user-info'
}

export function login(accountNo, password, captchaVerification, userType, loginType, device) {
  const data = {
    accountNo, password, captchaVerification , userType, loginType, device
  }
  return request({
    url: userApi.Login,
    method: 'post',
    data: data
  })
}
// 手机验证码登录
export function loginByMobile(data) {
  return request({
    url: '/login-by-phone',
    method: 'post',
    data: data
  })
}
// 图形验证码登录

// 注册方法
export function register(data) {
  return request({
    url: userApi.Register,
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout() {
  return request({
    url: userApi.Logout,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 登录前获取参数
export function getLoginConfig() {
  return request({
    url: '/common/get-login-config',
    method: 'get'
  })
}
// 获取验证码
// export function getCodeImg() {
//   return request({
//     url: '/captchaImage',
//     method: 'get'
//   })
// }

export function getCodeImg() {
  return request({
    url: '/get-pic-captcha',
    method: 'get'
  })
}
// 获取验证码
export function getSmsCode(data) {
  return request({
    url: '/get-phone-valid-code',
    method: 'get',
    params: data
  })
}
